import React, { useState } from "react";
import "../Styles/HomeScreen.css";
import logo from "../logo192.png";
import arrow from "../bentarrow2.png";
import pin from "../pins.webp";
import { client, server } from '@passwordless-id/webauthn';
import { getNewChallenge } from '../utils';
import { getAuthNUsers } from '../utils';
import { v4 as uuidv4 } from 'uuid';

function HomeScreen() {
  const [username, setUsername] = useState('');

  const registerUser = async (e) => {
    e.preventDefault();
    if (username.length < 4) {
      alert('Characters should be more than 3');
      setUsername('');
      return;
    }
    const challengeBytes = uuidv4();
    try {
      const registration = await client.register(username, challengeBytes, {
        authenticatorType: 'auto',
        userVerification: 'required',
        timeout: 60000,
        attestation: true,
        userHandle: username,
        debug: false,
      });
      await verifyUser(challengeBytes, registration);
    } catch (error) {
      console.error(error);
    }
  };

  const verifyUser = async (challenge, regObj) => {
    const expected = {
      challenge: challenge,
      origin: window.location.origin,
    };
    try {
      const registrationParsed = await server.verifyRegistration(
        regObj,
        expected
      );
      const allUsers = getAuthNUsers();
      allUsers.push(registrationParsed);
      localStorage.setItem('authnUsers', JSON.stringify(allUsers));
      alert('Registration Was successful with Self Pass');
    } catch (error) {
      console.log(error);
    }
  };

  const authenticateUser = async () => {
    if (username.length < 4) {
      alert('Characters should be more than 3');
      setUsername('');
      return;
    }

    const allUsers = getAuthNUsers();
    const existingUser = allUsers.find((user) => user.username === username);
    if (!existingUser) {
      alert('User has not registered');
      return;
    }

    try {
      const challengeBytes = uuidv4();

      const authentication = await client.authenticate(
        [existingUser.credential.id],
        challengeBytes,
        {
          authenticatorType: 'auto',
          userVerification: 'required',
          timeout: 60000,
        }
      );

      if (!authentication) {
        alert("An unexpected Error occurred");
        return;
      }
      await authenticateOnServer(authentication, challengeBytes, existingUser.credential)

    } catch (error) {
      console.error(error)
    }
  };

  const authenticateOnServer = async (authentication, challenge, credentialKey) => {

    try {
      const expected = {
        challenge: challenge,
        origin: window.location.origin,
        userVerified: true,
      };

      const authenticationParsed = await server.verifyAuthentication(authentication, credentialKey, expected);
      if (!authenticationParsed) {
        throw new Error("Unexpected Error Occurred");
      }
      window.location.replace(`${window.location.href}welcome.html`)

    } catch (error) {
      console.error(error)
    }


  }

  return (
    <div className="relative lg:top-[9rem] top-[5rem]">
      <div className="flex justify-center items-center lg:mb-3 mb-8">
        <p className="home-font2 mr-2 relative tracking-tighter top-[.2rem] text-fuchsia-800 lg:text-lg text-sm font-medium button-font  ">
          Powered by
        </p>
        <img className=" block w-5 mt-[0.5rem]" src={logo} alt="logo-design" />
        <p className="relative top-[.2rem] text-lg home-font2 font-bold button-font  ">
          DMS
        </p>
      </div>
      <h1 className="lg:text-7xl text-4xl text-neutral-800 lg:font-extrabold mx-3 font-extrabold home-font2 lg:leading-[4.9rem] leading-[2.4rem] tracking-tighter">
        Try next-level security.
        <br></br>Go Passwordless!
      </h1>
      <h1 className="lg:px-[27rem] mb-4 px-[2.5rem] text-neutral-800 lg:mt-3 mt-2 home-font2 font-normal lg:leading-[2rem] lg:text-[1.4rem] text-sm">
        Self Pass improves the digital experience by offering
        frictionless authentication{" "}
      </h1>
      <form onSubmit={registerUser} className="flex text-center absolute justify-center bg-slate-50 lg:mx-[30rem] mx-[3rem] shadow-xl shadow-fuchsia-200  border-4 border-fuchsia-100 rounded-full py-[0.2rem] px-2 lg:mt-[4.5rem] mt-[3.5rem]">
        <input
          name="username" type="text" id="username"
          className="outline-none   lg:w-[17rem] w-12/12 lg:h-16 h-8 lg:text-lg bg-transparent text-sm font-light  lg:px-6 lg:py-6 home-font2 "
          placeholder="Enter your email address"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <button type="submit" className="text-white lg:mt-2  bg-fuchsia-900 tracking-tighter lg:shadow-lg shadow-sm shadow-fuchsia-200 rounded-3xl lg:h-12 h-9 lg:w-[10rem] w-[7rem] home-font2 lg:text-lg text-sm lg:font-semibold font-bold ml-3 lg:px-4 px-[0.3rem]">
          Click and See ✨
        </button>
        {/* <button type="button" onClick={authenticateUser} className="text-white lg:mt-2  bg-fuchsia-900 lg:shadow-lg shadow-sm shadow-fuchsia-200 rounded-3xl lg:h-12 h-9 lg:w-[10rem] home-font2 lg:text-lg text-sm lg:font-semibold font-bold ml-3 lg:px-4 px-[0.2rem]">
          Click and see ✨
        </button> */}
      </form>
      <div className=" lg:rounded-3xl rounded-lg bg-fuchsia-100 lg:shadow-2xl shadow-xl  lg:shadow-fuchsia-600 shadow-fuchsia-300 lg:pt-[2rem] pt-2 lg:mt-[8rem] mt-[7rem] lg:mx-[17rem] mx-5 text-center lg:h-[14.5rem] h-[14rem]">
        <div className=" rounded-xl bg-white shadow-lg lg:mt-6 mt-8 lg:mx-[10rem] text-center lg:h-[11rem] h-[10rem] mx-6 ">
          <div className="flex">
            <div className="flex lg:gap-2 gap-1 lg:ml-6 ml-4 lg:pt-6 pt-4">
              <div className="rounded-full bg-red-400 lg:h-[0.6rem] lg:w-[0.6rem] h-[0.4rem] w-[0.4rem]"></div>
              <div className="rounded-full bg-yellow-400 lg:h-[0.6rem] lg:w-[0.6rem] h-[0.4rem] w-[0.4rem]"></div>
              <div className="rounded-full bg-green-400 lg:h-[0.6rem] lg:w-[0.6rem] h-[0.4rem] w-[0.4rem]"></div>
            </div>
            <div className="bg-fuchsia-50 lg:w-2/12 w-3/12 lg:h-[1.5rem] h-[1.2rem] lg:mt-4 mt-3 lg:ml-[10rem] ml-[4rem] home-font2 rounded-full">
              <h1 className="lg:text-sm text-xs home-font-colour lg:font-semibold font-normal">
                selfpass.xyz
              </h1>
            </div>
          </div>
          <div className="flex lg:ml-[4rem] ml-[2rem] lg:mt-6 mt-5">
            <div className="rounded-full bg-yellow-200 lg:text-2xl text-lg lg:h-[3rem] lg:w-[3rem] lg:pt-2 pt-0.5 h-[2rem] w-[2rem]  ">🧸</div>
            <div className="lg:ml-4 ml-2 home-font2">
              <h1 className="lg:text-xl text-sm font-semibold">Your Email</h1>
              <h1 className="lg:text-base text-xs text-gray-500 font-extralight  text-left">
                XXXXXX
              </h1>
            </div>
          </div>
          <div
            className="outline-none lg:ml-[6rem] flex text-left text-neutral-800 justify-start font-light ml-[4rem] home-font2 lg:mt-3 mt-2 lg:w-80 w-8/12 lg:h-10 h-7 bg-neutral-100 lg:text-lg text-xs rounded-2xl pointer-events-none lg:pt-2 pt-[0.2rem] lg:pl-4 pl-2"
          >{username}</div>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
