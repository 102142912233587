import React from "react";


function NavBar() {
  return (
    <div className="navbar fixed flex  z-50 py-4 lg:px-[14rem]  lg:top-[1.2rem] px-[2rem]  items-center w-full shadow-sm justify-between   ">
      <div className="">
        <h1 className="tracking-tighter lg:text-4xl text-2xl text-neutral-800 font-semibold home-font">Self Pass</h1>
      </div>
      <div className="flex gap-5 home-font2 text-lg">
      <button
							className="hover:bg-gray-100 text-neutral-500 px-5 h-[2.5rem] rounded-3xl hidden lg:block "
							to="/registration/hospital"
						>
							<h1 className="">Login</h1>
						</button>
						<button
							className="bg-gray-200 text-neutral-800 px-5 h-[2.5rem] rounded-3xl  hidden lg:block"
							to="/registration/doctors"
						>
							<h1 className="">Sign Up</h1>
						</button>
      </div>
    </div>
  );
}

export default NavBar;
