import logo from './logo.svg';
import './App.css';
import HomeScreen from './Component/HomeScreen';
import NavBar from './Component/NavBar';

function App() {
  return (
    <div className="App">
      <NavBar />
      <HomeScreen />
    </div>
  );
}

export default App;
